<template>
  <div class="card">
    <div class="card-header pt-1 pb-1">
      <div class="flex-grow-1">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="text-uppercase font-medium mb-0">Ara</label>
              <b-input-group>
                <b-form-input
                  v-model="sf.filter.service_list.keyword"
                  type="text"
                ></b-form-input>
                <b-input-group-append>
                  <button
                    class="btn btn-outline-secondary"
                    @click="sf.filter.service_list.keyword_type = 'device'"
                    :class="{
                      'bg-secondary text-white':
                        sf.filter.service_list.keyword_type == 'device',
                    }"
                  >
                    Cihaz
                  </button>
                  <button
                    class="btn btn-outline-secondary"
                    @click="sf.filter.service_list.keyword_type = 'customer'"
                    :class="{
                      'bg-secondary text-white':
                        sf.filter.service_list.keyword_type == 'customer',
                    }"
                  >
                    Müşteri
                  </button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="text-uppercase font-medium mb-0">Tarih</label>
              <flat-pickr
                v-model="sf.filter.service_list.date_range"
                class="form-control"
                :config="{
                  dateFormat: 'd.m.Y',
                  altFormat: 'Y-m-d - Y-m-d',
                  mode: 'range',
                  altInput: false,
                }"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="text-uppercase font-medium mb-0">Marka</label>
              <v-select
                v-model="sf.filter.service_list.brand_id"
                :options="global.device_brands"
                :reduce="(option) => option.definition_id"
                :searchable="true"
                :clearable="false"
              />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="text-uppercase font-medium mb-0">Durum</label>
              <v-select
                multiple
                v-model="sf.filter.service_list.status_ids"
                :options="global.device_status"
                :reduce="(option) => option.value"
                label="label"
                :searchable="true"
                :clearable="false"
              />
            </div>
          </div>
          <div class="col-sm-3">
            <div class="d-flex">
              <b-form-checkbox
                v-model="sf.filter.service_list.is_immediate"
                class="custom-control"
              >
                <span class="font-medium text-uppercase"> Acil</span>
              </b-form-checkbox>
              <b-form-checkbox
                v-model="sf.filter.service_list.is_form_empty"
                class="custom-control ml-2"
              >
                <span class="font-medium text-uppercase"> Cihazsız</span>
              </b-form-checkbox>
            </div>
            <b-form-checkbox
              v-model="sf.filter.service_list.is_hasproblem"
              class="custom-control"
            >
              <span class="font-medium text-uppercase"> Problemli Cihaz</span>
            </b-form-checkbox>
          </div>
          <div class="col-sm-2">
            <div class="form-group mt-2">
              <button @click="getServiceList()" class="btn btn-sm btn-primary">
                Filtrele
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <b-table
        responsive
        striped
        show-empty
        small
        :items="datasource.table.items"
        :fields="datasource.table.fields"
        emptyText="Görüntülenecek bir servis kayıtı bulunmuyor."
        class="mb-0 firstlast-padding"
      >
        <template #cell(customer)="data">
          <div v-if="data.item.customer != null">
            <div class="d-flex flex-column align-items-start">
              <div class="font-weight-bold">
                {{ data.item.customer.title }}
                <span>({{ data.item.customer.customer_code }})</span>
              </div>
              <div>
                <a :href="'tel:' + data.item.customer.gsm_primary">{{
                  data.item.customer.gsm_primary
                }}</a>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-danger text-bold">Müşteri Seçilmedi</div>
          </div>
        </template>
        <template #cell(sf_no_date)="data">
          <div>{{ data.item.sf.sf_no }}</div>
          <div>{{ moment(data.value.sf_date).format("DD.MM.YYYY") }}</div>
          <div>
            <router-link
              class="d-block badge badge-primary mb-05"
              :to="{
                name: 'service',
                query: { uniq: data.item.sf.sf_uniq },
              }"
            >
              Servis Form
            </router-link>
            <router-link
              class="d-block badge badge-primary mb-05"
              :to="{
                name: 'tservice',
                query: { uniq: data.item.sf.sf_uniq },
              }"
            >
              Teknik Form
            </router-link>
          </div>
        </template>
        <template #cell(device)="data">
          <div v-if="data.value == '' || data.value == null">
            <span class="badge badge-light-warning">Cihaz Eklenmedi</span>
          </div>
          <div v-else>
            <div class="row">
              <div class="col-sm-4 font-weight-bold">Marka</div>
              <div class="col-sm-8">
                {{ data.value.brand_name }}
                {{ data.value.model_name }}
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 font-weight-bold">Durum</div>
              <div class="col-sm-8">
                <status-badge :status="data.value.status" />
              </div>
            </div>
            <div class="row">
              <div class="col-sm-4 font-weight-bold">Çıkması Gereken T.</div>
              <div class="col-sm-8">
                <span class="mr-1" v-if="data.item.device.due_date != null">{{
                  moment(data.item.device.due_date).format("DD.MM.YYYY")
                }}</span>
                <span
                  v-if="data.item.device.is_immediate == 1"
                  class="badge badge-danger"
                  >ACİL</span
                >
              </div>
            </div>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr";
flatpickr.localize(Turkish);
import api from "@/service/api";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusBadge,
    VueAutosuggest,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      datasource: {
        table: {
          items: [],
          fields: [
            {
              key: "customer",
              label: "Müşteri",
              sortable: true,
            
            },
            { key: "sf_no_date", label: "Servis No", sortable: true },
            { key: "device", label: "Cihaz" },
          ],
        },
      },
    };
  },
  created() {
    let daterange =
      this.moment().add(-50, "days").format("DD.MM.YYYY") +
      " - " +
      this.moment().add(10, "days").format("DD.MM.YYYY");
    this.sf.filter.service_list.brand_id = "0";
    this.sf.filter.service_list.date_range = daterange;
    this.getServiceList();
  },
  methods: {
    getServiceList() {
      let postData = this.sf.filter.service_list;
      api.action("getServices", { ...postData }).then((q) => {
        if (q.success) {
          this.datasource.table.items = [];

          //her cihaz için sf göster

          if (this.sf.filter.service_list.is_form_empty) {
            let tmpSF = [];
            q.data.service_forms.forEach((sf) => {
              let devices = q.data.devices.filter((qm) => qm.sf_id == sf.sf_id);
              if (devices.length == 0) {
                let customer = q.data.customers.find(
                  (qm) => qm.customer_id == sf.customer_id
                );
                let item = {
                  sf: sf,
                  customer: customer,
                  device: null,
                };
                tmpSF.push(item);
              } else {
                devices.forEach((d) => {
                  let customer = q.data.customers.find(
                    (qm) => qm.customer_id == sf.customer_id
                  );
                  let item = {
                    sf: sf,
                    customer: customer,
                    device: d,
                  };
                  tmpSF.push(item);
                });
              }
            });
            this.datasource.table.items = tmpSF;
          } else {
            this.datasource.table.items = [];
            q.data.devices.forEach((qq) => {
              let sf = q.data.service_forms.find((qm) => qm.sf_id == qq.sf_id);
              let customer_id = sf != null ? sf.customer_id : 0;
              let customer = q.data.customers.find(
                (qm) => qm.customer_id == customer_id
              );
              let item = {
                sf: sf,
                customer: customer,
                device: qq,
              };
              this.datasource.table.items.push(item);
            });
          }

          /*
      q.data.devices.forEach((device) => {
            let sf = q.data.service_forms.find((qm) => qm.sf_id == device.sf_id);
            let sf_date =
              sf != null ? this.moment(sf.sf_date).format("DD.MM.YYYY") : null;

            let item = 
            {
              sf: sf,
              sf_no_date: sf,
              device: device,
              customer: device.customer,
              sf_date: sf_date,
              status: device.status,
            };

            this.datasource.table.items.push(item);
          });
    */

          /*
          q.data.service_forms.forEach((qq) => {
            let item = {
              sf: qq,
              customer: qq.customer,
              sf_no: qq.sf_no,
              sf_date: qq.sf_date,
              status: { status: qq.status, status_text: qq.status_text },
            };
            this.datasource.table.items.push(item);
          });
          */
        }
      });
    },
    moment: function (item) {
      return moment(item);
    },
    shortUserName(username) {
      if (username == null) return;
      let result = "";
      if (username.includes(" ")) {
        result = username.split(" ")[0][0] + "" + username.split(" ")[1][0];
      } else {
        result = username[0];
      }
      return result;
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
 